import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Landing from './Landing';
import Room from './Room';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route exact path="/room/:id">
          <Room />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
