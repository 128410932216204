import Peer, { DataConnection } from 'peerjs';

const peerOptions = { host: 'eknos.io', port: 9000, path: '/myapp', secure: true };

function generatePeerId() {
  return Math.random().toString(36).slice(7);
}

function getPeer(): Promise<{ peer: Peer, id: string }> {
  return new Promise((resolve) => {
    const peer = new Peer(generatePeerId(), peerOptions);
    peer.on('open', id => {
      if (id == null) {
        console.log('Peer ID conflict, trying again');
        resolve(getPeer());
      } else {
        resolve({ peer, id });
      }
    });
  })
}

interface ConnectedPeer {
  id: string,
  stream: MediaStream,
  dataConn?: DataConnection,
}

interface ConnectedDataPeer extends ConnectedPeer {
  dataConn: DataConnection,
}

function playAudioStream(stream: MediaStream) {
  const el = document.createElement('audio');
  el.autoplay = true;
  el.srcObject = stream;
  document.body.appendChild(el);
}

export async function createRoom(setConnectedPeers: (mediaStreams: MediaStream[]) => void): Promise<string> {
  // Grab the stream and peer in parallel
  const [myStream, { peer, id }] = await Promise.all([
    navigator.mediaDevices.getUserMedia({ video: false, audio: true }),
    getPeer(),
  ]);
  let connectedPeers: ConnectedDataPeer[] = [];

  peer.on('call', async (call) => {
    console.log('Incoming Call');
    call.answer(myStream);
    call.on('stream', stream => {
      const dataConn = peer.connect(call.peer);
      for (const connectedPeer of connectedPeers) {
        connectedPeer.dataConn.send({
          type: 'discoveredPeer',
          peerId: connectedPeer.id,
        });
      }
      connectedPeers = [...connectedPeers, {
        id: call.peer,
        stream,
        dataConn,
      }];
      setConnectedPeers(connectedPeers.map(x => x.stream));
  
      dataConn.on('open', () => {
        for (const connectedPeer of connectedPeers) {
          dataConn.send({
            type: 'discoveredPeer',
            peerId: connectedPeer.id,
          });
        }
      });
    });
  });

  return id;
}

export async function joinRoom(room: string, setConnectedPeers: (streams: MediaStream[]) => void) {
  // Grab the stream and peer in parallel
  const [myStream, { peer, id }] = await Promise.all([
    navigator.mediaDevices.getUserMedia({ video: false, audio: true }),
    getPeer(),
  ]);

  const call = peer.call(room, myStream);
  let connectedPeers: ConnectedPeer[] = [];

  call.on('stream', (stream) => {
    connectedPeers = [...connectedPeers, {
      id: call.peer,
      stream,
    }];
    setConnectedPeers(connectedPeers.map(x => x.stream));
  });

  peer.on('connection', (conn) => {
    conn.on('data', (data) => {
      switch (data.type) {
        case 'discoveredPeer': {
          if (data.peerId === id || connectedPeers.includes(data.peerId)) break;
          console.log('Discovered peer', data.peerId, '- calling');
          const call = peer.call(data.peerId, myStream);
          call.on('stream', stream => {
            connectedPeers = [...connectedPeers, {
              id: data.peerId,
              stream,
            }];
            setConnectedPeers(connectedPeers.map(x => x.stream));
          });
        }
      }
    })
  })
}