import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import styles from './Landing.module.css';

function Landing() {
  const [roomCode, setRoomCode] = useState('');

  return (<Container maxWidth="sm">
    <Box pt={15}>
      <Button variant="contained" color="primary" fullWidth component={Link} to="/room/create-room">Create Room</Button>
      <Box py={5}><Typography variant="h3" className={styles.centered}>OR</Typography></Box>
      <Box pb={2}><TextField label="Room Code" variant="filled" fullWidth value={roomCode} onChange={input => setRoomCode(input.target.value)} /></Box>
      <Button variant="contained" color="primary" fullWidth component={Link} to={'/room/' + roomCode}>Join Room</Button>
    </Box>
  </Container>);
}

export default Landing;