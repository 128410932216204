import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Slider from '@material-ui/core/Slider';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { createRoom, joinRoom } from './peer';

function Room() {
  const { id } = useParams();
  const creatingRoom = id === 'create-room';
  const [roomCode, setRoomCode] = useState(creatingRoom ? 'creating...' : id);
  const [connectedPeers, setConnectedPeers] = useState<MediaStream[]>([]);
  const [volume, setVolume] = useState(100);

  useEffect(() => {
    (async () => {
      if (creatingRoom) {
        setRoomCode(await createRoom(setConnectedPeers));
      } else if (id != null) {
        await joinRoom(id, setConnectedPeers);
      }
    })();
  }, [id]);

  return (<Box>
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6">
          Room: {roomCode}{roomCode !== 'creating...' && ` (${connectedPeers.length} peers)`}
        </Typography>
      </Toolbar>
    </AppBar>
    <Container maxWidth="sm">
      <Box pt={4}>
        <Typography variant="h6">Volume</Typography>
        <Slider value={volume} onChange={(_, v) => setVolume(Array.isArray(v) ? v[0] : v)} />
        { connectedPeers.map(x => (<audio autoPlay ref={el => el != null && (el.srcObject = x)} />)) }
      </Box>
    </Container>
  </Box>);
}

export default Room;